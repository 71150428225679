import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Switch,
    TextField,
    CircularProgress,
    Box,
    Typography,
    InputBase,
    Snackbar,
    Checkbox,
    FormControlLabel,
    Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";

const Container = styled(Box)({
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
});

const SearchBar = styled(InputBase)({
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "5px 10px",
    width: "100%",
    marginBottom: "20px",
});

const CenteredSpinner = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
});

const RowContainer = styled(Box)({
    position: "relative",
    display: "flex",
    alignItems: "center",
});

const SpinnerOverlay = styled(Box)({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
});

const FiltersRow = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
});

const StockLevelManager = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [updateInProgress, setUpdateInProgress] = useState({});
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [filters, setFilters] = useState({
        outOfStock: false,
        inStock: false,
        managed: false,
        unmanaged: false,
    });

    const token = sessionStorage.getItem("pfresh_main_jwt_token");

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get("https://petfresh.com.au/wp-json/wc/v3/stock-levels", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setProducts(response.data);
                setFilteredProducts(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, [token]);

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        filterProducts({ searchQuery: query, ...filters });
    };

    const handleStockUpdate = async (productId, field, value) => {
        setUpdateInProgress((prev) => ({ ...prev, [productId]: true }));
        try {
            await axios.post(
                "https://petfresh.com.au/wp-json/wc/v3/update-stock-level",
                { productId, [field]: value },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setSnackbarMessage("Stock updated successfully!");
            setUpdateInProgress((prev) => ({ ...prev, [productId]: false }));
        } catch (error) {
            console.error("Error updating stock:", error);
            setUpdateInProgress((prev) => ({ ...prev, [productId]: "failed" }));
        }
    };

    const filterProducts = (updatedFilters) => {
        setFilters(updatedFilters);
        let filtered = products;

        if (updatedFilters.outOfStock) {
            filtered = filtered.filter((product) => product["CURRENT STOCK STATUS"] === "Out of Stock");
        }
        if (updatedFilters.inStock) {
            filtered = filtered.filter((product) => product["CURRENT STOCK STATUS"] === "In Stock");
        }
        if (updatedFilters.managed) {
            filtered = filtered.filter((product) => product["QTY MANAGED"] === "Yes");
        }
        if (updatedFilters.unmanaged) {
            filtered = filtered.filter((product) => product["QTY MANAGED"] !== "Yes");
        }
        if (searchQuery) {
            filtered = filtered.filter(
                (product) =>
                    product.Product.toLowerCase().includes(searchQuery) ||
                    (product.SKU && product.SKU.toLowerCase().includes(searchQuery))
            );
        }

        setFilteredProducts(filtered);
    };

    if (loading)
        return (
            <CenteredSpinner>
                <CircularProgress />
            </CenteredSpinner>
        );

    const groupedProducts = filteredProducts.reduce((acc, product) => {
        const category = product.CATEGORY || "Uncategorized";
        if (!acc[category]) acc[category] = [];
        acc[category].push(product);
        return acc;
    }, {});

    return (
        <Container>
            <Typography variant="h5" gutterBottom>
                Stock Level Manager
            </Typography>
            <SearchBar
                placeholder="Search by product name or SKU..."
                value={searchQuery}
                onChange={handleSearch}
            />
            <FiltersRow>
                {["outOfStock", "inStock", "managed", "unmanaged"].map((filter) => (
                    <FormControlLabel
                        key={filter}
                        control={
                            <Checkbox
                                checked={filters[filter]}
                                onChange={(e) => filterProducts({ ...filters, [filter]: e.target.checked })}
                            />
                        }
                        label={filter.replace(/([A-Z])/g, " $1").trim()}
                    />
                ))}
            </FiltersRow>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: "30%" }}>Product</TableCell>
                        <TableCell style={{ width: "15%" }}>SKU</TableCell>
                        <TableCell style={{ width: "10%" }}>Status</TableCell>
                        <TableCell style={{ width: "20%" }}>Stock Managed</TableCell>
                        <TableCell style={{ width: "25%" }}>Stock Level</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(groupedProducts).map((category) => (
                        <React.Fragment key={category}>
                            <TableRow>
                                <TableCell colSpan={5} style={{ fontWeight: "bold" }}>
                                    {category}
                                </TableCell>
                            </TableRow>
                            {groupedProducts[category].map((product) => (
                                <TableRow key={product.SKU}>
                                    <TableCell>{product.Product}</TableCell>
                                    <TableCell>{product.SKU}</TableCell>
                                    <TableCell>
                                        <Tooltip
                                            title={
                                                updateInProgress[product.SKU] === "failed"
                                                    ? "Failed Stock Update"
                                                    : product["CURRENT STOCK STATUS"]
                                            }
                                        >
                                            <Box
                                                style={{
                                                    backgroundColor:
                                                        updateInProgress[product.SKU] === "failed"
                                                            ? "orange"
                                                            : product["CURRENT STOCK STATUS"] === "In Stock"
                                                            ? "green"
                                                            : "red",
                                                    borderRadius: "50%",
                                                    width: "12px",
                                                    height: "12px",
                                                }}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={product["QTY MANAGED"] === "Yes"}
                                            onChange={(e) =>
                                                handleStockUpdate(product.SKU, "managed", e.target.checked)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <RowContainer>
                                            {updateInProgress[product.SKU] && (
                                                <SpinnerOverlay>
                                                    <CircularProgress size={20} />
                                                </SpinnerOverlay>
                                            )}
                                            {product["QTY MANAGED"] === "Yes" ? (
                                                <TextField
                                                    defaultValue={product["STOCK LEVEL"]}
                                                    onBlur={(e) =>
                                                        handleStockUpdate(product.SKU, "stockLevel", e.target.value)
                                                    }
                                                    disabled={updateInProgress[product.SKU]}
                                                />
                                            ) : (
                                                <TextField
                                                    select
                                                    defaultValue={product["CURRENT STOCK STATUS"]}
                                                    onChange={(e) =>
                                                        handleStockUpdate(product.SKU, "status", e.target.value)
                                                    }
                                                    disabled={updateInProgress[product.SKU]}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                >
                                                    <option value="In Stock">In Stock</option>
                                                    <option value="Out of Stock">Out of Stock</option>
                                                </TextField>
                                            )}
                                        </RowContainer>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <Snackbar
                open={!!snackbarMessage}
                autoHideDuration={3000}
                onClose={() => setSnackbarMessage("")}
                message={snackbarMessage}
            />
        </Container>
    );
};

export default StockLevelManager;
